<template>
  <client-only>
    <TailwindComponentsLoading
      v-if="isLoading"
      fullScreen
    ></TailwindComponentsLoading>
    <div v-else>
      <h2 class="text-primary">
        {{ $t('Transkribus Server Jobs') }}
      </h2>
      <div class="flex justify-end">
        <NuxtLink
          :to="
            localePath({
              name: 'jobs',
            })
          "
        >
          <BaseButton
            :label="$t('Open Full Jobs Table >')"
            @click="openJobs"
            :type="'text'"
            small
            icon="chevron"
          />
        </NuxtLink>
      </div>

      <TailwindComponentsTable :data="jobs" :columns="columns" :total="total">
        <template #action-field="{ row, column }">
          <div
            class="app-card__dropdown app-card__dropdown--top content-center max-h-8"
          >
            <label tabindex="0" class="app-card__dropdown__btn">
              <i
                class="mdi mdi-dots-horizontal app-card__dropdown__btn__icon"
              ></i>
            </label>
            <ul
              tabindex="0"
              class="app-card__dropdown__menu dropdown-content app-card__dropdown__menu--jobs z-10"
            >
              <li v-if="row['state'] === 'FINISHED'">
                <a
                  v-if="row['type'] === 'Export Document'"
                  :href="row['result']"
                  target="_blank"
                >
                  <i class="mdi mdi-download"></i>
                  <span>{{ $t('Download') }}</span>
                </a>

                <NuxtLink
                  v-else
                  :to="getPathForNuxtLink(row)"
                  @click="selected(row)"
                  ><i class="mdi mdi-open-in-new"></i>
                  <span>{{ $t('Open') }}</span></NuxtLink
                >
              </li>
              <li v-if="row['state'] == 'FINISHED' && canUndo(row)">
                <span @click="jobsApi.undoJob(row)">
                  <i class="mdi mdi-undo"></i>
                  <span>{{ $t('Undo') }}</span>
                </span>
              </li>
              <li v-if="showRestart(row)">
                <span @click="restartJob(row)">
                  <i class="mdi mdi-restart"></i>
                  <span>{{ $t('Restart') }}</span>
                </span>
              </li>
              <li v-if="showCancel(row)">
                <span @click="cancelJob(row)">
                  <i class="mdi mdi-cancel"></i>
                  <span>{{ $t('Cancel') }}</span>
                </span>
              </li>
              <li
                v-if="
                  row['state'] != 'FINISHED' &&
                  !showRestart(row) &&
                  !showCancel(row)
                "
              >
                <span>{{ $t('Actions not possible') }}</span>
              </li>
            </ul>
          </div>
        </template>
        <template #title-field="{ row, column }">
          <NuxtLink
            :to="getPathForNuxtLink(row)"
            @click="selected(row)"
            class="text-primary font-bold"
            v-if="row[column.field]"
            >{{ truncate(row[column.field], 20) }}</NuxtLink
          >
          <TailwindComponentsLoading small v-else></TailwindComponentsLoading>
        </template>
        <template #jobtype-field="{ row, column }">
          <BaseTooltip
            :tip="descriptionValue(row[column.field])"
            small
            v-if="descriptionValue(row[column.field])?.length > 25"
          >
            <template v-slot:content>
              {{ truncate(descriptionValue(row[column.field]), 25) }}
            </template>
          </BaseTooltip>

          <span v-else class="truncate max-w-xs">{{
            descriptionValue(row[column.field])
          }}</span>
        </template>
        <template #start-field="{ row, column }">
          <BaseDate :value="row[column.field]" showTime />
        </template>
        <template #status-field="{ row, column }">
          <div
            v-if="
              row[column.field] === 'RUNNING' || row[column.field] === 'CREATED'
            "
            class="px-3 py-1 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200"
          >
            {{ row[column.field] }} ...
          </div>
          <span v-else :class="statusTag(row[column.field])">{{
            row[column.field]
          }}</span>
        </template>
      </TailwindComponentsTable>
    </div>
  </client-only>
</template>

<script>
export default {
  setup() {
    const jobsApi = useJobs()
    return {
      jobsApi,
    }
  },
  watch: {
    status(value) {
      this.$nextTick(function () {
        this.getCount()
        this.getJobs()
      })
    },
  },
  data() {
    return {
      jobs: [],
      isLoading: false,
      total: 12,
      polling: null,
      timeout: null,
      collId: null,
      filterType: null,
      status: undefined,
      filterByUser: true,
      sortField: 'jobId',
      sortOrder: 'desc',
      descriptionMappings: {
        'CITlab Handwritten Text Recognition': 'Text Recognition',
        'PyLaia Decoding': 'Text Recognition',
        TranskribusLaJobMultiThread: 'Layout analysis',
        'Transformer Proc Api Text Recognition':
          'Text Recognition - Super Model (API)',
        'PyLaia Proc Api Decoding': 'Text Recognition (API)',
        TrHtrJob: 'Text Recognition - Super Model',
        'Layout Analysis 2': 'Field Recognition',
        'Layout Analysis 2 Train': 'Field Model Training',
        PyLaiaDecodingJob: 'Text Recognition',
        PyLaiaTrainingJob: 'Text Model Training',
        'Text to Image Matching': 'Text Import',
      },
      columns: [
        {
          field: 'colId',
          label: this.$t('Collection ID'),
          sortable: true,
          visible: false,
        },
        {
          field: 'jobId',
          label: 'Job Id',
          sortable: true,
          visible: false,
        },
        {
          field: 'userName',
          label: this.$t('User'),
          sortable: true,
          visible: false,
        },
        {
          field: 'docId',
          label: this.$t('Document ID'),
          sortable: true,
          visible: false,
        },
        {
          field: 'docTitle',
          label: this.$t('Title'),
          numeric: false,
          searchable: false,
          sortable: false,
          visible: true,
          display: true,
          custom: true,
          slotName: 'title-field',
        },
        {
          field: 'type',
          label: this.$t('Job'),
          numeric: false,
          sortable: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'jobtype-field',
        },
        {
          field: 'started',
          label: this.$t('Start'),
          sortable: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'start-field',
        },
        {
          field: 'ended',
          label: this.$t('End'),
          sortable: true,
          visible: false,
        },
        {
          field: 'pages',
          label: this.$t('Pages'),
          width: 2,
          sortable: true,
          visible: true,
        },
        {
          field: 'state',
          label: this.$t('Status'),
          sortable: true,
          visible: true,
          display: true,
          custom: true,
          slotName: 'status-field',
        },
        {
          field: 'nrOfErrors',
          label: this.$t('Errors'),
          sortable: true,
          visible: false,
        },
        {
          field: 'description',
          label: this.$t('Description'),
          sortable: true,
          visible: true,
        },
        {
          field: 'action',
          label: this.$t('Action'),
          custom: true,
          display: true,
          slotName: 'action-field',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.getCount()
      this.getJobs()
    })
    this.$bus.on('slideOver', () => {
      this.getCount()
      this.getJobs()
    })
  },
  beforeDestroy() {
    this.stopPolling()
    this.$bus.off('slideOver')
  },
  methods: {
    async getCount() {
      let data
      try {
        data = await this.jobsApi.fetchJobsCount({
          filterByUser: this.filterByUser,
          type: this.filterType,
          status: this.status,
        })
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
      this.total = parseInt(data)
    },
    async getJobs() {
      this.isLoading = true
      let data
      try {
        data = await this.jobsApi.fetchJobs({
          index: 0,
          nValues: 12,
          sortDirection: this.sortOrder.toUpperCase(),
          sortColumn: this.sortField,
          filterByUser: this.filterByUser,
          collId: this.collId,
          type: this.filterType,
          status: this.status,
        })
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
      this.jobs = data
      const unfinishedJobs = this.jobs.filter(
        job =>
          job.state == 'RUNNING' ||
          job.state == 'CREATED' ||
          job.state == 'WAITING'
      )
      if (unfinishedJobs.length > 0) {
        await this.startPolling(unfinishedJobs)
      }
      this.$nextTick(() => {
        this.isLoading = false
      })
      this.setLoading(false)
    },
    statusTag(value) {
      if (value == 'FINISHED') {
        return 'badge rounded-full bg-success text-white'
      }
      if (value == 'FAILED') {
        return 'badge rounded-full bg-danger text-white'
      } else {
        return 'badge rounded-full bg-warning text-white'
      }
    },
    setLoading(value) {
      this.$nextTick(() => (this.isLoading = value))
    },
    descriptionValue(value) {
      if (value === undefined) {
        return value
      }

      for (const key in this.descriptionMappings) {
        if (this.descriptionMappings.hasOwnProperty(key)) {
          value = value.replaceAll(key, this.descriptionMappings[key])
        }
      }
      return value
    },
    async cancelJob(job) {
      let notification

      try {
        await this.jobsApi.updateJob({
          jobId: job.jobId,
        })

        notification = {
          message: this.$t('Job was cancelled'),
          type: 'success',
        }
        await this.getJobs()

        this.$bus.emit('notification', notification)
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
    },
    async restartJob(job) {
      let notification

      try {
        await this.jobsApi.updateJob({
          jobId: job.jobId,
          state: 'CREATED',
        })

        notification = {
          message: this.$t('Restart a job ' + job.jobId),
          type: 'success',
        }
        await this.getJobs()

        this.$bus.emit('notification', notification)
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
    },
    async undoJob(job) {
      let notification

      try {
        await this.jobsApi.undoJob({
          jobId: job.jobId,
        })

        notification = {
          message: this.$t('Undo a job ' + job.jobId),
          type: 'success',
        }
        await this.getJobs()

        this.$bus.emit('notification', notification)
      } catch (err) {
        this.$sentry.captureException(err)
        return
      }
    },
    canUndo(job) {
      return (
        job.jobImpl === 'TrHtrJob' ||
        job.jobImpl === 'FinereaderLaJob' ||
        job.jobImpl === 'KrakenLaJobMultiThread' ||
        job.jobImpl === 'LayoutAnalysis2Job' ||
        job.jobImpl === 'PyLaiaDecodingJob' ||
        job.jobImpl === 'PyLaiaKwsDecodingJob' ||
        job.jobImpl === 'PyLaiaOcrJob' ||
        job.jobImpl === 'P2PaLAJobMultiThread' ||
        job.jobImpl === 'ReplaceJob' ||
        job.jobImpl === 'TableRecognitionJob' ||
        job.jobImpl === 'TranskribusLaJobMultiThread'
      )
    },
    // comment polling use refresh button
    async startPolling() {
      let data
      const that = this
      this.polling = setInterval(async function () {
        try {
          const jobsApi = useJobs()
          data = await jobsApi.fetchJobs({ status: 'UNFINISHED' })
        } catch (err) {
          console.log(err)
          // this.$sentry.captureException(err)
          return
        }

        for (const temp in data) {
          const job = data[temp]
          const index = that.jobs.findIndex(el => el.jobId === job.jobId)
          that.$nextTick(() => {
            that.jobs.splice(index, 1)
            that.jobs.splice(index, 0, job)
          })
        }
        if (data.length == 0) {
          return that.stopPolling()
        }
      }, 20000)
    },
    stopPolling() {
      clearInterval(this.polling)
      this.getJobs()
    },
    openJobs() {
      this.$bus.emit('slideOverClose')
    },
    getPathForNuxtLink(item) {
      if (item.docId == -1) {
        return ''
      } else {
        if (item.pages) {
          const pageNumber = item.pages.split(/[, -]+/)[0]
          return this.localePath(
            `/collection/${item.colId}/doc/${item.docId}/edit?pageid=${pageNumber}`
          )
        } else {
          return this.localePath(`/collection/${item.colId}/doc/${item.docId}`)
        }
      }
    },

    selected(item) {
      if (item.docId == -1) {
        let notification = {
          message: this.$t('Jobs does not reference document'),
          type: 'error',
        }
        this.$bus.emit('notification', notification)
      } else if (
        item.state == 'FINISHED' &&
        item.docTitle !== 'Deleted Document'
      ) {
        // Check if document is already deleted

        this.$emit('closeModal')
      } else if (item.docTitle === 'Deleted Document') {
        let notification = {
          message: this.$t('Document has been deleted'),
          type: 'error',
        }
        this.$bus.emit('notification', notification)
      } else {
        let notification = {
          message: this.$t('Job failed or is not finnished'),
          type: 'error',
        }
        this.$bus.emit('notification', notification)
      }
    },
    truncate(str, n) {
      const truncateText = useTruncate(str, n)
      return truncateText
    },
    showCancel(job) {
      const jobState = job.state

      return (
        (job.userId === this.userId || this.isAdmin) &&
        (jobState === 'CREATED' ||
          jobState === 'WAITING' ||
          jobState === 'RUNNING')
      )
    },
    showRestart(job) {
      const jobState = job.state

      return (
        this.isAdmin &&
        (jobState === 'FINISHED' ||
          jobState === 'CANCELED' ||
          jobState === 'FAILED')
      )
    },
  },
  computed: {
    isAdmin() {
      const { userProfile } = useKeycloak()
      return userProfile?.IsAdmin
    },
    userId() {
      const { userProfile } = useKeycloak()
      return userProfile?.UserId
    },
  },
}
</script>
